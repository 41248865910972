// extracted by mini-css-extract-plugin
export var bgImageP = "Workflow-module--bgImageP--a7f44";
export var h5 = "Workflow-module--h5--c5757";
export var layoutHorizontal = "Workflow-module--layoutHorizontal--a1a2f";
export var layoutVertical = "Workflow-module--layoutVertical--e6158";
export var ulBullets0 = "Workflow-module--ulBullets0--5a3e5";
export var ulBullets1 = "Workflow-module--ulBullets1--548ae";
export var ulBullets2 = "Workflow-module--ulBullets2--93836";
export var ulBullets3 = "Workflow-module--ulBullets3--4d0d8";
export var ulBullets4 = "Workflow-module--ulBullets4--0389b";
export var workflow = "Workflow-module--workflow--903c4";