import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Workflow.module.scss"

const Workflow = props => {
  const { title, secImages, subTitle, subTitle2, cards } = props
  return (
    <section className={styles.workflow}>
      <Container>
        <div className="text-center mb-4">
          <h2
            className="h1 main-heading-36"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
        {subTitle2 === "cards" && (
          <React.Fragment>
            <Row className="justify-content-center">
              <Col md={7}>
                <div
                  className="mb-5 text-center px-md-5"
                  dangerouslySetInnerHTML={{ __html: subTitle }}
                />
              </Col>
            </Row>
            <Row className="pb-5">
              {cards?.map(({ title, subTitle }, i) => (
                <Col sm={12} md={6} lg={3} key={title + i}>
                  <h3 className={styles?.h5}>{title}</h3>
                  <div className="mb-4 mb-lg-0">{subTitle}</div>
                </Col>
              ))}
            </Row>
          </React.Fragment>
        )}
        {subTitle2 === "bgImage" && (
          <Row
            className={`align-items-center ${styles.layoutHorizontal} ml-md-5 pt-4`}
          >
            <Col md={6} className="text-center">
              <img
                src={secImages[0]?.localFile?.publicURL}
                alt="workflows"
                width="100%"
                decoding="async"
                loading="lazy"
              />
            </Col>
            <Col md={6} className={styles.bgImageP}>
              <div dangerouslySetInnerHTML={{ __html: subTitle }} />
            </Col>
          </Row>
        )}
        {subTitle2 === "horizontal" && (
          <Row
            className={`align-items-center ${styles.layoutHorizontal} ml-md-5`}
          >
            <Col md={6} className="text-center mb-5">
              <img
                src={secImages[0]?.localFile?.publicURL}
                alt="workflows"
                width="75%"
                decoding="async"
                loading="lazy"
              />
            </Col>
            <Col md={6} className="pt-5 mt-md-0 ">
              <div
                className="pb-3 pl-md-5"
                dangerouslySetInnerHTML={{ __html: subTitle }}
              />
              <ul className="mt-3 pl-0 pl-md-5">
                {cards?.map(({ title, subTitle }, i) => (
                  <Row className="justify-content-center" key={title + i}>
                    <Col xs={2} md={2} lg={1}>
                      <div className={styles[`ulBullets${i}`]} />
                    </Col>
                    <Col xs={10} md={10} lg={11} className="pl-0 pl-md-3 ">
                      <li key={i}>
                        <h4>{title}</h4>
                        <div className="pb-5 pt-1">{subTitle}</div>
                      </li>
                    </Col>
                  </Row>
                ))}
              </ul>
            </Col>
          </Row>
        )}
        {subTitle2 === "vertical" && (
          <div className={styles.layoutVertical}>
            <Row className="justify-content-center">
              <Col md={8}>
                <div dangerouslySetInnerHTML={{ __html: subTitle }} />
              </Col>
            </Row>
            <div className="mt-5 pb-5">
              <img
                src={secImages[0]?.localFile?.publicURL}
                alt="workflows"
                width="80%"
                decoding="async"
                loading="lazy"
              />
            </div>
          </div>
        )}
      </Container>
    </section>
  )
}

export default Workflow
