import React from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./SubServices.module.scss"

const SubServices = props => {
  const { title, subTitle, cards } = props
  return (
    <section className={`${styles.subservice}`}>
      <Container>
        <div className="p-1 p-md-3">
          <Row>
            <Col sm={12} md={11}>
              <h2
                className="main-heading-36 pb-3"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={11}>
              <div
                dangerouslySetInnerHTML={{ __html: subTitle }}
                className="pr-md-4"
              />
            </Col>
          </Row>
        </div>
        <Row>
          {cards?.map(({ image1, title, subTitle }, i) => (
            <Col xl={4} lg={6} key={i} className="p-0 p-md-2">
              <Card className={styles.subserviceCard}>
                <Card.Body>
                  <div className="pb-3">
                    <img
                      src={image1[0]?.localFile?.publicURL}
                      alt={title}
                      decoding="async"
                      loading="lazy"
                    />
                  </div>
                  <Card.Title>
                    <h3 className="h5">{title}</h3>
                  </Card.Title>
                  <Card.Text dangerouslySetInnerHTML={{ __html: subTitle }} />
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default SubServices
