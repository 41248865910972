import { Link } from "gatsby"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = props => {
  const { title, subTitle, cards, secImages } = props
  return (
    <section className={styles.banner}>
      <Container>
        <nav className="mb-4">
          <Link to={`/`} className="text-dark">
            Home
          </Link>
          {" » "}
          {props?.breadCrumb?.map((val, i) =>
            val?.url !== "#" ? (
              <React.Fragment key={i}>
                <Link to={`/${val?.url}/`} className="text-dark">
                  {val?.title}
                </Link>
                {" » "}
              </React.Fragment>
            ) : (
              <span className="text-dark">{val?.title}</span>
            )
          )}
        </nav>
        <Row className="align-items-center">
          <Col md={6}>
            <h1
              className={styles.mainHeading}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              className={`${styles.subHeading} pb-4 pt-2`}
              dangerouslySetInnerHTML={{ __html: subTitle }}
            />
            <ul className="pr-2 pr-md-0">
              {cards?.map(({ title }, i) => (
                <Row className="pb-2" key={`service_banner${i}`}>
                  <Col xs={1} className="pr-0">
                    <div className={styles.liBullet} />
                  </Col>
                  <Col xs={11} className="px-0">
                    <li key={i}>{title}</li>
                  </Col>
                </Row>
              ))}
            </ul>
            <div className="pt-4">
              <Link to="/contact-us/">
                <Button className="btn_black_border">
                  Discuss Your Project
                </Button>
              </Link>
            </div>
          </Col>
          <Col md={6} className="text-center mt-5 mt-md-0">
            <img
              className={styles.bannerImg}
              src={secImages[0]?.localFile?.publicURL}
              alt={title}
              width="100%"
              decoding="async"
              loading="lazy"
              height="auto"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner
